<template>
  <div class="mobileSwitch">
    <mHead :name="'智能开关'" :add="'/mobile/ProSwitch'" :adds="'/mobile/ProSwitchParam'" :color="true" />
    <div class="banner">
      <div class="bannerBox">
        <div class="bannerTit">一身才华，一触即发</div>
        <div class="bannerTits">智能开关</div>
        <div class="ul">
          <div class="li">四个按钮，随心定制</div>
          <div class="li">智能联动，一按即达</div>
        </div>
        <div class="ul">
          <div class="li">不用动手，语音控制</div>
          <div class="li">过温过载保护，守护安全</div>
        </div>
      </div>
    </div>
    <div class="sec1">
      <div class="sec1Tit">无框设计，出色质感，百搭配色</div>
      <div class="sec1Text">简洁的无框设计，</div>
      <div class="sec1Text sec1Texts">优雅奢华的香槟金，简约大气的黑色，深邃沉稳的绅士灰。</div>
      <div class="sec1Text sec1Texts">轻松搭配各种装修风格，提升品味，打造奢华。</div>
      <div class="imgBox">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item>
            <img src="../../assets/mobile/product/switch1.png" class="sec1Img" />
          </van-swipe-item>
          <van-swipe-item>
            <img src="../../assets/mobile/product/switch2.png" class="sec1Img" />
          </van-swipe-item>
          <van-swipe-item>
            <img src="../../assets/mobile/product/switch3.png" class="sec1Img" />
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="sec2">
      <div class="sec2Tit">无线互联，摆脱束缚</div>
      <div class="sec2Text">全新的高可靠自研无线组网方案，高度适配硬件，</div>
      <div class="sec2Text sec2Texts">并对酒店物联网特别优化。</div>
      <div class="sec2Text sec2Texts">搭配超级主机，在解决布线繁琐的同时，稳定性也丝毫不减。</div>
      <img src="../../assets/mobile/product/switch4.png" class="sec2Img" />
    </div>
    <div class="sec3">
      <div class="sec3Tit">无限场景，自由定义</div>
      <div class="sec3Text">搭配其他智能设备和超级主机，</div>
      <div class="sec3Text sec3Texts">可以根据您的需求自由定义丰富的场景控制。</div>
      <div class="sec3Text sec3Texts">开创性实现了一个开关既可以实现控制基础照明，</div>
      <div class="sec3Text sec3Texts">也可以控制场景的多重功能，轻松实现更多场景。</div>
      <img src="../../assets/mobile/product/switch5.png" class="sec3Img" />
      <img src="../../assets/mobile/product/switch6.png" class="sec3Img" />
    </div>
    <div class="sec4">
      <div class="sec4Tit">时时刻刻，感受自动化的新魅力</div>
      <div class="se">
        <div class="imgBox">
          <img src="../../assets/mobile/product/switch7.png" class="seImg" />
        </div>
        <div class="seTit">开门亮灯 贴心感知</div>
        <div class="seText">刷卡开门，灯光即自动亮起，电子设备、家电便开始工作。</div>
        <div class="seText seTexts">打造用户入住新体验。</div>
      </div>
      <div class="se">
        <div class="imgBox">
          <img src="../../assets/mobile/product/switch8.png" class="seImg" />
        </div>
        <div class="seTit">离店从容 尽享安心</div>
        <div class="seText">准备出门，一键开启离店模式，灯光、窗帘自动关闭。</div>
        <div class="seText seTexts">出门关灯，尽享轻松。</div>
      </div>
      <div class="se">
        <div class="imgBox">
          <img src="../../assets/mobile/product/switch9.png" class="seImg" />
        </div>

        <div class="seTit">一键场景 一键关灯</div>
        <div class="seText">躺在床上，伸手按一下摆在床头的开关，灯光即可全部关闭。</div>
        <div class="seText seTexts">房间灯光，轻松掌握。</div>
      </div>
    </div>
  </div>
</template>

<script>
import mHead from "../../components/MobileHead.vue";
export default {
  components: {
    mHead
  },
  data() {
    return {};
  }
};
</script>
<style scoped lang="less">
.mobileSwitch {
  background-color: #fff;
  .banner {
    width: 100%;
    height: 8rem;
    background-image: url(../../assets/mobile/product/switchBanner.png);
    background-size: 100% 100%;
    .bannerBox {
      width: 100%;
      text-align: center;
      .bannerTit {
        padding-top: 0.47rem;
        font-size: 0.3rem;
        line-height: 0.3rem;
        font-weight: 400;
        color: #000000;
      }
      .bannerTits {
        font-size: 0.48rem;
        line-height: 0.48rem;
        font-weight: bold;
        color: #ff9000;
        margin-top: 0.19rem;
      }
      .ul {
        display: flex;
        font-size: 0.24rem;
        line-height: 0.24rem;
        font-weight: 400;
        color: #333333;
        padding-left: 0.98rem;
        margin-top: 0.4rem;
        &:last-of-type {
          margin-top: 0.22rem;
        }
        .li {
          height: 0.24rem;
          padding-right: 0.3rem;
          border-right: 1px solid #ff9000;
          &:nth-of-type(2n) {
            border-right: 0px;
            padding-left: 0.3rem;
          }
        }
      }
    }
  }
  .sec1 {
    margin-top: 0.79rem;
    font-size: 0.36rem;
    line-height: 0.36rem;
    font-weight: bold;
    color: #333333;
    /deep/.van-swipe__indicator {
      width: 0.16rem;
      height: 0.16rem;
      margin-right: 0.16rem;
      background-color: #999;
      opacity: 1;
      &:last-of-type{
        margin-right: 0;
      }
      // border: 0.04rem solid #666;
      // &:nth-of-type(2) {
      //   background-color: #6d6d6d;
      // }
      // &:nth-of-type(3) {
      //   background-color: #b8b093;
      //   margin-right: 0;
      // }
    }
    /deep/ .van-swipe__indicators {
      bottom:0.6rem;
    }
    // /deep/ .van-swipe__indicator--active {
    //   // border: 0px;
    //   // border: 0.04rem solid #ff9000 !important;
    // }
    .my-swipe {
      width: 100%;
      height: 100%;
      .van-swipe-item {
        width: 100%;
        height: 100%;

        .img {
          width: 100%;
          height: 100%;
        }
        .name {
          width: 100%;
          font-size: 0.24rem;
          line-height: 0.24rem;
          font-weight: 400;
          color: #333333;
          position: absolute;
          bottom: 1.36rem;
        }
      }
    }
    .sec1Text {
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
      margin-top: 0.4rem;
    }
    .sec1Texts {
      margin-top: 0.16rem;
    }
    .imgBox {
      width: 100%;
      margin-top: 0.4rem;

      .sec1Img {
        width: 100%;
        height: 8.4rem;
      }
    }
  }
  .sec2 {
    padding: 0.8rem 0.3rem;
    background-color: #f8f8f8;
    font-size: 0.36rem;
    line-height: 0.36rem;
    font-weight: bold;
    color: #333333;
    .sec2Text {
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
      margin-top: 0.4rem;
    }
    .sec2Texts {
      margin-top: 0.16rem;
    }
    .sec2Img {
      width: 100%;
      margin-top: 0.4rem;
    }
  }
  .sec3 {
    margin-top: 0.8rem;
    background-color: #fff;
    font-size: 0.36rem;
    line-height: 0.36rem;
    font-weight: bold;
    color: #333333;
    .sec3Text {
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
      margin-top: 0.4rem;
    }
    .sec3Texts {
      margin-top: 0.16rem;
    }
    .sec3Img {
      width: 100%;
      height: 8rem;
      margin-top: 0.4rem;
      display: block;
      &:last-of-type {
        margin-top: 0px;
      }
    }
  }
  .sec4 {
    padding: 0.8rem 0.3rem;
    font-size: 0.36rem;
    line-height: 0.36rem;
    font-weight: bold;
    color: #333333;
    .se {
      background-color: #f8f8f8;
      margin-top: 0.3rem;
      padding: 0.3rem 0px;
      &:last-of-type {
        margin-top: 0.4rem;
      }
      .imgBox {
        padding: 0px 0.3rem;
        .seImg {
          width: 100%;
        }
      }

      .seTit {
        margin-top: 0.26rem;
      }
      .seText {
        margin-top: 0.28rem;
        font-size: 0.24rem;
        line-height: 0.24rem;
        font-weight: 400;
      }
      .seTexts {
        margin-top: 0.16rem;
      }
    }
  }
}
</style>


